import { Link } from "gatsby"
import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"

import ContentContainer from "@components/ContentContainer"
import SEO from "@components/SEO/seo"
import { StaticNavbarLayout } from "@components/layouts/static-navbar-layout"

const NotFoundPage = () => (
  <StaticNavbarLayout>
    <SEO title="404: Not found" keywords={["Page not found"]} />
    <ContentContainer rowConstrained={true} sectionClassesExtra="section">
      <Col xs={12}>
        <h1>Page not found</h1>
        <p>You just hit a route that doesn&#39;t exist.</p>
        <Link to="/">
          <Button>Return Home</Button>
        </Link>
      </Col>
    </ContentContainer>
  </StaticNavbarLayout>
)

export default NotFoundPage
